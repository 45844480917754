import { template as template_ee3a60f562b9477fb2edbeeba5cb5ba1 } from "@ember/template-compiler";
const FKLabel = template_ee3a60f562b9477fb2edbeeba5cb5ba1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
