import { template as template_c9b4f7c2524344afacc5bc6b49271543 } from "@ember/template-compiler";
const WelcomeHeader = template_c9b4f7c2524344afacc5bc6b49271543(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
