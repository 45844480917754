import { template as template_16e29fbfb56f45c9bfdf664f79d9a367 } from "@ember/template-compiler";
const FKText = template_16e29fbfb56f45c9bfdf664f79d9a367(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
